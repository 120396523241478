import React, {Component} from 'react';
import firebase from "gatsby-plugin-firebase";
import * as routes from '../../constants/routes';
import '../../Content/css/authentication.css';
import FacebookLogo from '../../Content/images/logos/facebook.png';
import GoogleLogo from '../../Content/images/logos/google.png';
import ShareImg from '../../Content/images/NewImageKeto.jpg'
import Helmet from 'react-helmet';
import {Link, navigate} from 'gatsby';
import {Event} from '../Analytics/';
const byPropKey = (propertyName, value) => () => ({[propertyName]: value});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  wrapperStyle: {}
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this
      .updateWindowDimensions
      .bind(this)
    this.state = {
      ...INITIAL_STATE
    };
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({
      wrapperStyle: {
        minHeight: window.innerHeight,
        width: window.innerWidth
      }
    })
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));

  }
  handleFacebookSignin() {
    const facebookProvider = new firebase
      .auth
      .FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(facebookProvider)
      .then((result) => {
        this.storeUserFromSocialLogin(result.user, 'Facebook');
      })
  }
  handleGoogleSignin() {
    const googleProvider = new firebase
      .auth
      .GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((result) => {
        this.storeUserFromSocialLogin(result.user, 'Google');
      })
  }
  storeUserFromSocialLogin(user, provider) {
    Event("User Login", provider, '/login');
    firebase
      .database()
      .ref(`users/${user.uid}`)
      .update({
        username: user.displayName,
        email: user.email,
        avatar: user.photoURL,
        emailVerified: user.emailVerified,
        provider: 'Google',
        fresh: true,
        skipTour: false
      })
      .then(() => {
        this.setState({
          ...INITIAL_STATE
        });
        navigate(routes.PROFILE);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
  }

  onSubmit = (event) => {
    event.preventDefault();
    const {email, password} = this.state;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        Event("User Login", "Email", "/login");
        this.setState({
          ...INITIAL_STATE
        });
        navigate(routes.PROFILE);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
  }

  render() {
    const {email, password, error} = this.state;
    const isInvalid = password === '' || email === '';
    let shareImg = ShareImg;
    let windowUrl = ''
    if (typeof window !== 'undefined') {
      windowUrl = window.location.href;
    }
    let lg = this.state.wrapperStyle.width > 400;
    //
    return (
      <main>
        <Helmet>
          <title>Login</title>
          <meta
            name="description"
            content="Signin access to all of our member only features including exclusive keto recipes, personalised meal plans and customised shopping lists"/>
          <meta
            name="keywords"
            content="signup, login, keto, newimage, new image, alpha lipid SDII, weight programme, weight management, app, keto calculator"/>
          <meta property="og:image:width" content="1200"/>
          <meta property="og:image:height" content="630"/>
          <meta property="og:url" content={windowUrl}/>
          <meta
            property="og:title"
            content="Login"/>
          <meta
            name="twitter:description"
            content="Our member only features includes exclusive keto recipes, personalised meal plans, weight progress tracking, and customised shopping lists"/>
          <meta property="og:image" content={shareImg}/>
          <meta property="og:image:url" content={shareImg}/>
          <meta name="twitter:image" content={shareImg}/>
          <meta name="twitter:card" content={shareImg}/>
        </Helmet>
        <div
          className="fullPageFormFrame secondary w-full absolute top-0 py-20 grey"
          style={this.state.wrapperStyle}>
          <div
            className={lg
            ? "w-full max-w-md mx-auto h-auto flex items-center"
            : "w-full max-w-md mx-auto h-auto flex items-center"}>
            <div
              className={lg
              ? "form mx-auto bg-white p-10 rounded-lg w-full shadow-lg"
              : "form mx-auto bg-white p-5 rounded-lg w-full"}>
              <form onSubmit={this.onSubmit} className="loginForm signUpForm">
                <h1 className="text-2xl text-secondary font-medium mb-4 text-center">Login</h1>
                <div className="my-2">
                  <label htmlFor="email" className="block my-2 text-center">Email</label>
                  <input
                    className="formControl text-left"
                    value={email}
                    onChange={event => this.setState(byPropKey('email', event.target.value))}
                    type="text"
                    placeholder="Email Address"
                    name="email"
                    id="email"/>
                </div>
                <div className="my-2">
                  <label htmlFor="password" className="block my-2 text-center">Password</label>
                  <input
                    className="formControl text-left"
                    value={password}
                    onChange={event => this.setState(byPropKey('password', event.target.value))}
                    type="password"
                    placeholder="Password"
                    name="password"
                    id="password"/>
                </div>
                <div className="inputWrapper my-4 block">
                  <button
                    disabled={isInvalid}
                    type="submit"
                    className="orange rounded-lg bg-primary text-white ronded-lg py-2 w-full inline-block">
                    Sign In
                  </button>
                </div>
                <div className="my-2">
                  {error && <div className="text-red-500 font-bold">{error.message}</div>}
                </div>
                <div className="my-6 block text-center">
                  <Link to="/forgot-password" className="text-blue-400 font-medium block">Forgot Password?</Link>
                </div>
              </form>
              <div className="my-4">
                <button
                  className="facebookLoginButton inline-block rounded-lg py-2 w-full"
                  onClick={this
                  .handleFacebookSignin
                  .bind(this)}>
                  <img
                    src={FacebookLogo}
                    className="socialSignInLogo"
                    alt="Facebook Social Login Logo"/>
                  Sign in with Facebook
                </button>
              </div>
              <div className="my-4">
                <button
                  className="googleLoginButton inline-block rounded-lg py-2 w-full"
                  onClick={this
                  .handleGoogleSignin
                  .bind(this)}>
                  <img
                    src={GoogleLogo}
                    className="socialSignInLogo"
                    alt="Google Social Login Logo"/>
                  Sign in with Google
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
//
export default Login;